/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { fetchPlatforms, fetchPlatform } from './action';

export const platformsSlice = createSlice({
  name: 'platform',
  initialState: {
    platforms: [],
    platform: null,
    isLoading: false,
    error: null,
  },
  extraReducers: {
    [fetchPlatforms.pending]: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    [fetchPlatforms.fulfilled]: (state, action) => {
      state.platforms = action.payload;
      state.isLoading = false;
    },
    [fetchPlatforms.rejected]: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    },
    [fetchPlatform.pending]: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    [fetchPlatform.fulfilled]: (state, action) => {
      state.platform = action.payload;
      state.isLoading = false;
    },
    [fetchPlatform.rejected]: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    },
  },
});

export default platformsSlice.reducer;
