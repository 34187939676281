import { createSlice, createEntityAdapter } from '@reduxjs/toolkit';
import {
  createDiscount,
  fetchDiscounts,
  removeDiscount,
  updateDiscont,
} from './action';

export const discountsAdapter = createEntityAdapter({
  selectId: (discount) => discount._id,
});

const initialState = discountsAdapter.getInitialState({
  status: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed'
  error: null,
});

export const discountsSlice = createSlice({
  name: 'discounts',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchDiscounts.pending]: (state) => {
      state.status = 'loading';
    },
    [fetchDiscounts.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      discountsAdapter.upsertMany(state, action.payload);
    },
    [fetchDiscounts.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.payload;
    },
    [createDiscount.fulfilled]: (state, action) => {
      discountsAdapter.addOne(state, action.payload);
    },
    [updateDiscont.fulfilled]: (state, { payload }) => {
      const { _id: id, ...changes } = payload;
      discountsAdapter.updateOne(state, { id, changes });
    },
    [removeDiscount.fulfilled]: (state, action) => {
      discountsAdapter.removeOne(state, action.payload._id);
    },
  },
});

export const { selectAll: selectAllDiscounts } = discountsAdapter.getSelectors(
  (state) => state.discounts
);

export default discountsSlice.reducer;
