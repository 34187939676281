import React, { Suspense, lazy } from 'react';
import { Switch } from 'react-router';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import AuthenticatedRoute from './AuthenticatedRoute';
import UnauthenticatedRoute from './UnauthenticatedRoute';
import DefaultRoute from './DefaultRoute';

const Login = lazy(() =>
  import(/* webpackChunkName: "LoginPage" */ '../pages/Login')
);
const Users = lazy(() =>
  import(/* webpackChunkName: "UsersPage" */ '../pages/Users')
);
const Chapters = lazy(() =>
  import(/* webpackChunkName: "ChaptersPage" */ '../pages/Chapters')
);
const ChaptersCreate = lazy(() =>
  import(
    /* webpackChunkName: "ChaptersCreatePage" */ '../pages/Chapters/Create'
  )
);
const ChaptersEdit = lazy(() =>
  import(/* webpackChunkName: "ChaptersEditPage" */ '../pages/Chapters/Edit')
);
const StreamingPlatforms = lazy(() =>
  import(
    /* webpackChunkName: "StreamingPlatformsPage" */ '../pages/StreamingPlatforms'
  )
);
const StreamingPlatformsCreate = lazy(() =>
  import(
    /* webpackChunkName: "StreamingPlatformsCreatePage" */ '../pages/StreamingPlatforms/Create'
  )
);
const StreamingPlatformsEdit = lazy(() =>
  import(
    /* webpackChunkName: "StreamingPlatformsEditPage" */ '../pages/StreamingPlatforms/Edit'
  )
);
const Lessons = lazy(() =>
  import(/* webpackChunkName: "LessonsPage" */ '../pages/Lessons')
);
const Surprise = lazy(() =>
  import(/* webpackChunkName: "SurprisePage" */ '../pages/Surprise')
);
const LessonsCreate = lazy(() =>
  import(/* webpackChunkName: "LessonsCreatePage" */ '../pages/Lessons/Create')
);
const SurpriseCreate = lazy(() =>
  import(
    /* webpackChunkName: "SurpriseCreatePage" */ '../pages/Surprise/Create'
  )
);
const LessonsEdit = lazy(() =>
  import(/* webpackChunkName: "LessonsEditPage" */ '../pages/Lessons/Edit')
);
const SurpriseEdit = lazy(() =>
  import(/* webpackChunkName: "SurpriseEditPage" */ '../pages/Surprise/Edit')
);
const Videos = lazy(() =>
  import(/* webpackChunkName: "VideosPage" */ '../pages/Videos')
);
const VideosCreate = lazy(() =>
  import(/* webpackChunkName: "VideosCreatePage" */ '../pages/Videos/Create')
);
const VideosEdit = lazy(() =>
  import(/* webpackChunkName: "VideosEditPage" */ '../pages/Videos/Edit')
);
const Filmes = lazy(() =>
  import(/* webpackChunkName: "FilmesPage" */ '../pages/Filmes')
);
const FilmesEdit = lazy(() =>
  import(/* webpackChunkName: "FilmesEditPage" */ '../pages/Filmes/Edit')
);
const Events = lazy(() =>
  import(/* webpackChunkName: "EventsPage" */ '../pages/Events')
);
const EventsEdit = lazy(() =>
  import(/* webpackChunkName: "EventsEditPage" */ '../pages/Events/Edit')
);
const ComplementaryEvents = lazy(() =>
  import(
    /* webpackChunkName: "ComplementaryEventsPage" */ '../pages/ComplementaryEvents'
  )
);
const ComplementaryEventsEdit = lazy(() =>
  import(
    /* webpackChunkName: "ComplementaryEventsEditPage" */ '../pages/ComplementaryEvents/Edit'
  )
);

const Genres = lazy(() =>
  import(/* webpackChunkName: "GenresPage" */ '../pages/Genres')
);
const Configs = lazy(() =>
  import(/* webpackChunkName: "ConfigsPage" */ '../pages/Configs')
);

const Discounts = lazy(() =>
  import(/* webpackChunkName: "ConfigsPage" */ '../pages/Discounts')
);

const Wallets = lazy(() =>
  import(/* webpackChunkName: "ConfigsPage" */ '../pages/Wallets')
);

const Transactions = lazy(() =>
  import(/* webpackChunkName: "ConfigsPage" */ '../pages/Transactions')
);

export default function index() {
  const useStyles = makeStyles(() => ({
    center: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100vw',
      height: '100vh',
    },
  }));

  const classes = useStyles();
  return (
    <Suspense
      fallback={
        <div className={classes.center}>
          <CircularProgress />
        </div>
      }
    >
      <Switch>
        <UnauthenticatedRoute exact path="/login" component={Login} />
        <AuthenticatedRoute exact path="/admin/users" component={Users} />
        <AuthenticatedRoute exact path="/admin/chapters" component={Chapters} />
        <AuthenticatedRoute
          exact
          path="/admin/chapters/create"
          component={ChaptersCreate}
        />
        <AuthenticatedRoute
          exact
          path="/admin/chapters/edit/:id"
          component={ChaptersEdit}
        />
        <AuthenticatedRoute exact path="/admin/lessons" component={Lessons} />
        <AuthenticatedRoute exact path="/admin/surprise" component={Surprise} />
        <AuthenticatedRoute
          exact
          path="/admin/lessons/create"
          component={LessonsCreate}
        />
        <AuthenticatedRoute
          exact
          path="/admin/surprise/create"
          component={SurpriseCreate}
        />
        <AuthenticatedRoute
          exact
          path="/admin/lessons/edit/:id"
          component={LessonsEdit}
        />
        <AuthenticatedRoute
          exact
          path="/admin/surprise/edit/:id"
          component={SurpriseEdit}
        />
        <AuthenticatedRoute exact path="/admin/videos" component={Videos} />
        <AuthenticatedRoute
          exact
          path="/admin/videos/create"
          component={VideosCreate}
        />
        <AuthenticatedRoute
          exact
          path="/admin/videos/edit/:id"
          component={VideosEdit}
        />
        <AuthenticatedRoute
          exact
          path="/admin/streaming-platforms"
          component={StreamingPlatforms}
        />
        <AuthenticatedRoute
          exact
          path="/admin/streaming-platforms/create"
          component={StreamingPlatformsCreate}
        />
        <AuthenticatedRoute
          exact
          path="/admin/streaming-platforms/edit/:id"
          component={StreamingPlatformsEdit}
        />
        <AuthenticatedRoute exact path="/admin/films" component={Filmes} />
        <AuthenticatedRoute
          exact
          path="/admin/films/edit/:id"
          component={FilmesEdit}
        />
        <AuthenticatedRoute exact path="/admin/events" component={Events} />
        <AuthenticatedRoute
          exact
          path="/admin/events/edit/:id"
          component={EventsEdit}
        />
        <AuthenticatedRoute
          exact
          path="/admin/complementary-events"
          component={ComplementaryEvents}
        />
        <AuthenticatedRoute
          exact
          path="/admin/complementary-events/edit/:id"
          component={ComplementaryEventsEdit}
        />
        <AuthenticatedRoute exact path="/admin/wallets" component={Wallets} />
        <AuthenticatedRoute
          exact
          path="/admin/transactions"
          component={Transactions}
        />
        <AuthenticatedRoute exact path="/admin/genres" component={Genres} />
        <AuthenticatedRoute exact path="/admin/configs" component={Configs} />
        <AuthenticatedRoute
          exact
          path="/admin/discounts"
          component={Discounts}
        />
        <DefaultRoute exact path="*" />
      </Switch>
    </Suspense>
  );
}
