/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { fetchVideo, fetchVideos } from './action';

export const videoSlice = createSlice({
  name: 'video',
  initialState: {
    videos: [],
    count: 0,
    video: null,
    isLoading: true,
    error: null,
  },
  extraReducers: {
    [fetchVideos.pending]: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    [fetchVideos.fulfilled]: (state, action) => {
      state.videos = action.payload.data;
      state.count = action.payload.count;
      state.isLoading = false;
    },
    [fetchVideos.rejected]: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    },
    [fetchVideo.pending]: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    [fetchVideo.fulfilled]: (state, action) => {
      state.video = action.payload;
      state.isLoading = false;
    },
    [fetchVideo.rejected]: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    },
  },
});

export default videoSlice.reducer;
