import { createSlice } from '@reduxjs/toolkit';
import fetchWallet, {
  chargeWallet,
  fetchDiscountsWallet,
  getAllTransactions,
  getCreatersWallet,
  getTransactionLogs,
  updateTransactionStatus,
} from './action';

export const walletSlice = createSlice({
  name: 'wallet',
  initialState: {
    wallet: {},
    discountWallets: [],
    creatersWallet: [],
    transactions: [],
    logs: [],
    isLogsLoading: true,
    isLoading: true,
    error: null,
  },
  reducers: {
    initWallet: (state) => {
      state.wallet = {};
    },
  },
  extraReducers: {
    [fetchWallet.pending]: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    [fetchWallet.fulfilled]: (state, { payload }) => {
      state.wallet = payload;
      state.isLoading = false;
    },
    [fetchWallet.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.error = payload;
    },
    [fetchDiscountsWallet.pending]: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    [fetchDiscountsWallet.fulfilled]: (state, { payload }) => {
      state.discountWallets = payload;
      state.isLoading = false;
    },
    [fetchDiscountsWallet.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.error = payload;
    },
    [chargeWallet.pending]: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    [chargeWallet.fulfilled]: (state, { payload }) => {
      state.wallet = [payload];
      state.isLoading = false;
      state.error = null;
    },
    [chargeWallet.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.error = payload;
    },
    [getCreatersWallet.pending]: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    [getCreatersWallet.fulfilled]: (state, { payload }) => {
      state.creatersWallet = payload;
      state.isLoading = false;
      state.error = null;
    },
    [getCreatersWallet.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.error = payload;
    },
    [getAllTransactions.pending]: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    [getAllTransactions.fulfilled]: (state, { payload }) => {
      state.transactions = payload;
      state.isLoading = false;
      state.error = null;
    },
    [getAllTransactions.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.error = payload;
    },
    [getTransactionLogs.pending]: (state) => {
      state.isLogsLoading = true;
      state.error = null;
    },
    [getTransactionLogs.fulfilled]: (state, { payload }) => {
      state.logs = payload;
      state.isLogsLoading = false;
      state.error = null;
    },
    [getTransactionLogs.rejected]: (state, { payload }) => {
      state.isLogsLoading = false;
      state.error = payload;
    },
    [updateTransactionStatus.pending]: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    [updateTransactionStatus.fulfilled]: (state, { payload }) => {
      state.transactions = state.transactions.map((trans) =>
        trans._id === payload._id ? { ...trans, ...payload } : trans
      );
      state.isLoading = false;
      state.error = null;
    },
    [updateTransactionStatus.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.error = payload;
    },
  },
});

export const { initWallet } = walletSlice.actions;

export default walletSlice.reducer;
