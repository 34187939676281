import axiosInstance from '../../../helpers/axios';

export const getConfigs = () => {
  return axiosInstance.get('config/get_by_query');
};

export const postConfig = (data) => {
  return axiosInstance.post('config/store', data);
};

export const patchConfig = (data) => {
  return axiosInstance.patch(`config/${data._id}`, data);
};

export const deleteConfig = (configId) => {
  return axiosInstance.delete(`config/${configId}`);
};
