import axiosInstance from '../../../helpers/axios';

const getPlatformsRequest = () => {
  return axiosInstance.get('streaming/get_by_query');
};

const getPlatformRequest = (platformId) => {
  return axiosInstance.get(`streaming/get_by_query?streaming_id=${platformId}`);
};

const postPlatformRequest = (data) => {
  return axiosInstance.post('streaming/store', data);
};

const patchPlatformRequest = ({ form, platformId }) => {
  return axiosInstance.patch(`streaming/${platformId}`, form);
};

const deletePlatformRequest = (platformId) => {
  return axiosInstance.delete(`streaming/${platformId}`);
};

const uploadRequest = (data) => {
  return axiosInstance.post('auth/upload', data);
};

export {
  getPlatformsRequest,
  getPlatformRequest,
  postPlatformRequest,
  patchPlatformRequest,
  deletePlatformRequest,
  uploadRequest,
};
