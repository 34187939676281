import { configureStore } from '@reduxjs/toolkit';
import authReducer from './reducers/auth/reducer';
import chapterReducer from './reducers/chapter/slice';
import lessonReducer from './reducers/lesson/slice';
import platformReducer from './reducers/platform/slice';
import videoReducer from './reducers/video/slice';
import userReducer from './reducers/user/slice';
import walletReducer from './reducers/wallet/slice';
import categoryReducer from './reducers/category/slice';
import configReducer from './reducers/config/slice';
import countriesReducer from './reducers/countries/slice';
import discountsReducer from './reducers/discount/slice';
import surpriseReducer from './reducers/surprise/slice';

export default configureStore({
  reducer: {
    auth: authReducer,
    chapter: chapterReducer,
    lesson: lessonReducer,
    video: videoReducer,
    platform: platformReducer,
    user: userReducer,
    wallet: walletReducer,
    categories: categoryReducer,
    configs: configReducer,
    countries: countriesReducer,
    discounts: discountsReducer,
    surprise: surpriseReducer,
  },
  devTools: process.env.NODE_ENV !== 'production',
});
