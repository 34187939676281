import axiosInstance from '../../../helpers/axios';

export const getDiscounts = () => {
  return axiosInstance.get('discount/get_by_query');
};

export const postDiscount = (data) => {
  return axiosInstance.post('discount/store', data);
};

export const patchDiscount = (data) => {
  return axiosInstance.patch(`discount/${data._id}`, data);
};

export const deleteDiscount = (discountId) => {
  return axiosInstance.delete(`discount/${discountId}`);
};
