import { createAsyncThunk } from '@reduxjs/toolkit';
import { loginRequest, meRequest } from './apis';

const login = createAsyncThunk(
  'auth/login',
  async (data, { rejectWithValue }) => {
    try {
      const res = await loginRequest(data);
      const { token } = res.data.data;
      const me = await meRequest(token);
      const AuthenticatedUser = me.data.data;
      if (AuthenticatedUser.role === 'admin') {
        localStorage.setItem('token', token);
        localStorage.setItem('currentUser', JSON.stringify(AuthenticatedUser));
        return AuthenticatedUser;
      }
      return rejectWithValue('invalid email or password');
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);
export default login;
