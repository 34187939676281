import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

const DefaultRoute = ({ ...rest }) => {
  const { isAuthenticated } = useSelector((state) => state.auth);
  return (
    <Route
      {...rest}
      render={() => {
        switch (isAuthenticated) {
          case true:
            return <Redirect to="/admin/users" />;
          default:
            return <Redirect to="/login" />;
        }
      }}
    />
  );
};

export default DefaultRoute;
