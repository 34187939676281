import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

const UnathenticatedRoute = ({ component: Component, ...rest }) => {
  const { isAuthenticated } = useSelector((state) => state.auth);
  return (
    <Route
      {...rest}
      render={(props) => {
        switch (isAuthenticated) {
          case false:
            return <Component {...props} />;
          default:
            return <Redirect to="/admin/users" />;
        }
      }}
    />
  );
};

UnathenticatedRoute.propTypes = {
  component: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default UnathenticatedRoute;
