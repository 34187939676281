import { createSlice, createEntityAdapter } from '@reduxjs/toolkit';
import {
  createConfig,
  fetchConfigs,
  removeConfig,
  updateConfig,
} from './action';

export const configsAdapter = createEntityAdapter({
  selectId: (config) => config._id,
});

const initialState = configsAdapter.getInitialState({
  status: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed'
  error: null,
});

export const lessonsSlice = createSlice({
  name: 'configs',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchConfigs.pending]: (state) => {
      state.status = 'loading';
    },
    [fetchConfigs.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      configsAdapter.upsertMany(state, action.payload);
    },
    [fetchConfigs.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.payload;
    },
    [createConfig.fulfilled]: (state, action) => {
      configsAdapter.addOne(state, action.payload);
    },
    [updateConfig.fulfilled]: (state, { payload }) => {
      const { _id: id, ...changes } = payload;
      configsAdapter.updateOne(state, { id, changes });
    },
    [removeConfig.fulfilled]: (state, action) => {
      configsAdapter.removeOne(state, action.payload._id);
    },
  },
});

export const { selectAll: selectAllConfigs } = configsAdapter.getSelectors(
  (state) => state.configs
);

export default lessonsSlice.reducer;
