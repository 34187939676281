import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  deleteVideoRequest,
  getVideoRequest,
  getVideosRequest,
  patchVideoRequest,
  postVideoRequest,
  uploadRequest,
  getCountVideos,
  deleteVideosRequest,
} from './api';

const fetchVideos = createAsyncThunk(
  'video/fetchVideos',
  async ({ query, countQuery }, { rejectWithValue }) => {
    try {
      const res = await getVideosRequest(query);
      const count = await getCountVideos(countQuery);
      return { data: res.data.data, count: count.data.data };
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);
const fetchVideo = createAsyncThunk(
  'video/fetchVideo',
  async (videoId, { rejectWithValue }) => {
    try {
      const res = await getVideoRequest(videoId);
      return res.data.data[0];
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

const createVideo = createAsyncThunk('video/createVideo', async (data) => {
  const res = await postVideoRequest(data);
  return res.data.data;
});

const updateVideo = createAsyncThunk(
  'video/updateVideo',
  async ({ form, videoId }) => {
    const res = await patchVideoRequest({ form, videoId });
    return res.data.data;
  }
);

const uploadFile = createAsyncThunk('videos/uploadFile', async (payload) => {
  const res = await uploadRequest(payload);
  return res.data;
});

const deleteVideo = createAsyncThunk('video/deleteVideo', async (videoId) => {
  const res = await deleteVideoRequest(videoId);
  return res.data.data;
});

const deleteVideos = createAsyncThunk('video/deleteVideos', async (videos) => {
  const res = await deleteVideosRequest(videos);
  return res.data.data;
});

export {
  fetchVideos,
  fetchVideo,
  createVideo,
  updateVideo,
  deleteVideo,
  uploadFile,
  deleteVideos,
};
