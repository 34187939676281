import axiosInstance from '../../../helpers/axios';

const getLessonsRequest = (chapterId) => {
  return axiosInstance.get(
    `lesson/get_by_query${chapterId ? `?feature_id=${chapterId}` : ''}`
  );
};

const getLessonRequest = (lessonId) => {
  return axiosInstance.get(`lesson/get_by_query?lesson_id=${lessonId}`);
};

const postLessonRequest = (data) => {
  return axiosInstance.post('lesson/store', data);
};

const patchLessonRequest = ({ form, lessonId }) => {
  return axiosInstance.patch(`lesson/${lessonId}`, form);
};

const deleteLessonRequest = (lessonId) => {
  return axiosInstance.delete(`lesson/${lessonId}`);
};

export {
  getLessonsRequest,
  getLessonRequest,
  postLessonRequest,
  patchLessonRequest,
  deleteLessonRequest,
};
