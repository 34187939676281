/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { fetchLessons, fetchLesson, updateLesson } from './action';

export const lessonsSlice = createSlice({
  name: 'lesson',
  initialState: {
    lessons: [],
    lesson: null,
    isLoading: false,
    error: null,
  },
  reducers: {
    clearLessons: (state) => {
      state.lessons = [];
    },
  },
  extraReducers: {
    [fetchLessons.pending]: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    [fetchLessons.fulfilled]: (state, action) => {
      state.lessons = action.payload;
      state.isLoading = false;
    },
    [fetchLessons.rejected]: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    },
    [fetchLesson.pending]: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    [fetchLesson.fulfilled]: (state, action) => {
      state.lesson = action.payload;
      state.isLoading = false;
    },
    [fetchLesson.rejected]: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    },
    [updateLesson.fulfilled]: (state, action) => {
      state.lesson = action.payload;
    },
  },
});
export const { clearLessons } = lessonsSlice.actions;

export default lessonsSlice.reducer;
