import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  getLessonsRequest,
  getLessonRequest,
  postLessonRequest,
  patchLessonRequest,
  deleteLessonRequest,
} from './api';

const fetchLessons = createAsyncThunk(
  'lesson/fetchLessons',
  async (chapterId, { rejectWithValue }) => {
    try {
      const res = await getLessonsRequest(chapterId);
      return res.data.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

const fetchLesson = createAsyncThunk(
  'lesson/fetchLesson',
  async (lessonId, { rejectWithValue }) => {
    try {
      const res = await getLessonRequest(lessonId);
      return res.data.data[0];
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

const createLesson = createAsyncThunk('lesson/createLesson', async (data) => {
  const res = await postLessonRequest(data);
  return res.data.data;
});

const updateLesson = createAsyncThunk(
  'lesson/updateLesson',
  async ({ form, lessonId }) => {
    const res = await patchLessonRequest({ form, lessonId });
    return res.data.data;
  }
);

const deleteLesson = createAsyncThunk(
  'lesson/deleteLesson',
  async (lessonId) => {
    const res = await deleteLessonRequest(lessonId);
    return res.data.data;
  }
);

export { fetchLessons, fetchLesson, createLesson, updateLesson, deleteLesson };
