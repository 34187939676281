/* eslint-disable import/prefer-default-export */
import { createAsyncThunk } from '@reduxjs/toolkit';
import getUsers, { patchUser, deleteUser, postUser, postTeam } from './api';

const fetchUsers = createAsyncThunk(
  'user/fetchUsers',
  async (_, { rejectWithValue }) => {
    try {
      const res = await getUsers();
      return res.data.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const addUser = createAsyncThunk(
  'user/addUser',
  async (data, { rejectWithValue }) => {
    try {
      const res = await postUser(data);
      return res;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateUser = createAsyncThunk('updateUser', async (data) => {
  await patchUser(data);
  return data;
});

export const removeUser = createAsyncThunk('user/removeUser', async (data) => {
  const res = await deleteUser(data);
  return res.data.data;
});

export const storeTeam = createAsyncThunk('user/storeTeam', async (data) => {
  const res = await postTeam(data);
  return res.data.data;
});

export default fetchUsers;
