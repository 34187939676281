import { createAsyncThunk } from '@reduxjs/toolkit';
import { deleteConfig, getConfigs, patchConfig, postConfig } from './api';

export const fetchConfigs = createAsyncThunk(
  'configs/fetchConfigs',
  async (_, { rejectWithValue }) => {
    try {
      const res = await getConfigs();
      return res.data.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const createConfig = createAsyncThunk(
  'configs/createConfig',
  async (data) => {
    const res = await postConfig(data);
    return res.data.data;
  }
);

export const updateConfig = createAsyncThunk(
  'configs/updateConfig',
  async (data) => {
    const res = await patchConfig(data);
    return res.data.data;
  }
);

export const removeConfig = createAsyncThunk(
  'configs/removeConfig',
  async (configId) => {
    const res = await deleteConfig(configId);
    return res.data.data;
  }
);
