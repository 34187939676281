import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  getChaptersRequest,
  postChapterRequest,
  getChapterRequest,
  patchChapterRequest,
  deleteChapterRequest,
} from './api';

const fetchChapters = createAsyncThunk(
  'chapter/fetchChapters',
  async (_, { rejectWithValue }) => {
    try {
      const res = await getChaptersRequest();
      return res.data.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

const fetchChapter = createAsyncThunk(
  'chapter/fetchChapter',
  async (chapterId, { rejectWithValue }) => {
    try {
      const res = await getChapterRequest(chapterId);
      return res.data.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

const createChapter = createAsyncThunk(
  'chapter/createChapter',
  async (data) => {
    const res = await postChapterRequest(data);
    return res.data.data;
  }
);

const updateChapter = createAsyncThunk(
  'chapter/updateChapter',
  async ({ data, chapterId }) => {
    const res = await patchChapterRequest({ data, chapterId });
    return res.data.data;
  }
);

const deleteChapter = createAsyncThunk(
  'chapter/deleteChapter',
  async (chapterId) => {
    const res = await deleteChapterRequest(chapterId);
    return res.data.data;
  }
);

export {
  fetchChapters,
  fetchChapter,
  createChapter,
  updateChapter,
  deleteChapter,
};
