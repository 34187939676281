import React from 'react';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { SnackbarProvider } from 'notistack';
import Routes from './routes';
import axiosInstance from './helpers/axios';

export default function App() {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <SnackbarProvider maxSnack={3}>
        <Routes />
      </SnackbarProvider>
    </MuiPickersUtilsProvider>
  );
}
// it forces axios to have token for bieng successfull
axiosInstance.interceptors.request.use((req) => {
  if (localStorage.getItem('token'))
    req.headers.Authorization = localStorage.getItem('token');
  return req;
});
