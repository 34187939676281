import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  getPlatformsRequest,
  getPlatformRequest,
  postPlatformRequest,
  patchPlatformRequest,
  deletePlatformRequest,
  uploadRequest,
} from './api';

const fetchPlatforms = createAsyncThunk(
  'platform/fetchPlatforms',
  async (_, { rejectWithValue }) => {
    try {
      const res = await getPlatformsRequest();
      return res.data.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

const fetchPlatform = createAsyncThunk(
  'platform/fetchPlatform',
  async (platformId, { rejectWithValue }) => {
    try {
      const res = await getPlatformRequest(platformId);
      return res.data.data[0];
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

const createPlatform = createAsyncThunk(
  'platform/createPlatform',
  async (data) => {
    const res = await postPlatformRequest(data);
    return res.data.data;
  }
);

const updatePlatform = createAsyncThunk(
  'platform/updatePlatform',
  async ({ form, platformId }) => {
    const res = await patchPlatformRequest({ form, platformId });
    return res.data.data;
  }
);

const deletePlatform = createAsyncThunk(
  'platform/deletePlatform',
  async (platformId) => {
    const res = await deletePlatformRequest(platformId);
    return res.data.data;
  }
);

const uploadFile = createAsyncThunk('videos/uploadFile', async (payload) => {
  const res = await uploadRequest(payload);
  return res.data;
});

export {
  fetchPlatforms,
  fetchPlatform,
  createPlatform,
  updatePlatform,
  deletePlatform,
  uploadFile,
};
