import { createSlice } from '@reduxjs/toolkit';
import fetchUsers, { updateUser, removeUser } from './action';

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    users: [],
    isLoading: true,
    error: null,
  },
  reducers: {},
  extraReducers: {
    [fetchUsers.pending]: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    [fetchUsers.fulfilled]: (state, { payload }) => {
      state.users = payload;
      state.isLoading = false;
    },
    [fetchUsers.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.error = payload;
    },
    [updateUser.fulfilled]: (state, { payload }) => {
      const findUser = state.users.find((user) => user._id === payload.user_id);
      Object.assign(findUser, payload);
    },
    [removeUser.fulfilled]: (state, { payload }) => {
      state.users = state.users.filter((user) => user._id !== payload._id);
    },
  },
});

export default userSlice.reducer;
