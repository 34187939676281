import axiosIntance from '../../../helpers/axios';

const getWallet = (userId) => {
  return axiosIntance.get(`/wallet/show_by_user_last/${userId}`);
};

export const preStoreRequest = (payload) => {
  return axiosIntance.post('wallet/preStore', payload);
};

export const postStoreRequest = (payload) => {
  return axiosIntance.post('wallet/postStore', payload);
};

export const discountWalletsRequest = () => {
  return axiosIntance.get('wallet/discount-wallet');
};

export const getCreaterWalletRequest = () => {
  return axiosIntance.get(`creater-wallet/all`);
};

export const getAllTransactionsRequest = () => {
  return axiosIntance.get(`transaction/all`);
};

export const updateTransactionStatusRequest = (payload) => {
  return axiosIntance.post(`transaction/status`, payload);
};

export const updateWalletStatusReqeust = (payload) => {
  return axiosIntance.put(`creater-wallet`, payload);
};

export const getTransactionLogsRequest = (payload) => {
  return axiosIntance.get(`transaction/log/${payload}`);
};

export default getWallet;
