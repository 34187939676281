/* eslint-disable import/prefer-default-export */
import { createAsyncThunk } from '@reduxjs/toolkit';
import getWallet, {
  preStoreRequest,
  postStoreRequest,
  getCreaterWalletRequest,
  updateWalletStatusReqeust,
  getAllTransactionsRequest,
  updateTransactionStatusRequest,
  getTransactionLogsRequest,
  discountWalletsRequest,
} from './api';

const fetchWallet = createAsyncThunk(
  'wallet/fetchWallet',
  async (userId, { rejectWithValue }) => {
    try {
      const res = await getWallet(userId);
      return res.data.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const fetchDiscountsWallet = createAsyncThunk(
  'wallet/fetchDiscountsWallet',
  async (_, { rejectWithValue }) => {
    try {
      const res = await discountWalletsRequest();
      return res.data.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const getCreatersWallet = createAsyncThunk(
  'wallet/fetchCreaterWallet',
  async (userId, { rejectWithValue }) => {
    try {
      const res = await getCreaterWalletRequest(userId);
      return res.data.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const getAllTransactions = createAsyncThunk(
  'transactions/all',
  async (_, { rejectWithValue }) => {
    try {
      const res = await getAllTransactionsRequest();
      return res.data.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const getTransactionLogs = createAsyncThunk(
  'transactions/log',
  async (payload, { rejectWithValue }) => {
    try {
      const res = await getTransactionLogsRequest(payload);
      return res.data.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const updateTransactionStatus = createAsyncThunk(
  'transactions/update',
  async (payload, { rejectWithValue }) => {
    try {
      const res = await updateTransactionStatusRequest(payload);
      return res.data.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const updateWalletStatus = createAsyncThunk(
  'wallet/updateCreaterWallet',
  async (payload, { rejectWithValue }) => {
    try {
      const res = await updateWalletStatusReqeust(payload);
      return res.data.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const chargeWallet = createAsyncThunk(
  'wallet/chargeWallet',
  async ({ preStoreData, postStoreData }) => {
    const preRes = await preStoreRequest(preStoreData);
    const postRes = await postStoreRequest({
      key: preRes.data.data.key,
      ...postStoreData,
    });
    return postRes.data.data;
  }
);

// export const chargeWallet = createAsyncThunk(
//   'wallet/chargeWallet',
//   async (formData) => {
//     const { team, ...rest } = formData;
//     const preRes = await preStoreRequest(rest);
//     const postRes = await postStoreRequest({
//       key: preRes.data.data.key,
//       team,
//     });
//     return postRes.data.data;
//   }
// );

export default fetchWallet;
