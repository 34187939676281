import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

const AuthenticatedRoute = ({ component: Component, ...rest }) => {
  const { isAuthenticated } = useSelector((state) => state.auth);
  return (
    <Route
      {...rest}
      render={(props) => {
        switch (isAuthenticated) {
          case true:
            return <Component {...props} />;
          default:
            return <Redirect to="/login" />;
        }
      }}
    />
  );
};

AuthenticatedRoute.propTypes = {
  component: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default AuthenticatedRoute;
