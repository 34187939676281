import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  getSurprisesRequest,
  getSurpriseRequest,
  postSurpriseRequest,
  patchSurpriseRequest,
  deleteSurpriseRequest,
} from './api';

const fetchSurprises = createAsyncThunk('surprise/fetchSurprises', async () => {
  const res = await getSurprisesRequest();
  return res.data.data;
});

const fetchSurprise = createAsyncThunk(
  'surprise/fetchSurprise',
  async (surpriseId, { rejectWithValue }) => {
    try {
      const res = await getSurpriseRequest(surpriseId);
      return res.data.data[0];
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

const createSurprise = createAsyncThunk(
  'surprise/createSurprise',
  async (data) => {
    const res = await postSurpriseRequest(data);
    return res.data.data;
  }
);

const updateSurprise = createAsyncThunk(
  'surprise/updateSurprise',
  async ({ form, lessonId }) => {
    const res = await patchSurpriseRequest({ form, lessonId });
    return res.data.data;
  }
);

const deleteSurprise = createAsyncThunk(
  'surprise/deleteSurprise',
  async (surpriseId) => {
    const res = await deleteSurpriseRequest(surpriseId);
    return res.data.data;
  }
);

export {
  fetchSurprises,
  createSurprise,
  updateSurprise,
  deleteSurprise,
  fetchSurprise,
};
