import axiosInstance from '../../../helpers/axios';

const getSurprisesRequest = () => {
  return axiosInstance.get('surprise/get_by_query');
};

const getSurpriseRequest = (surpriseId) => {
  return axiosInstance.get(`surprise/get_by_query?surprise_id=${surpriseId}`);
};

const postSurpriseRequest = (data) => {
  return axiosInstance.post('surprise/store', data);
};

const patchSurpriseRequest = ({ form, surpriseId }) => {
  return axiosInstance.patch(`surprise/${surpriseId}`, form);
};

const deleteSurpriseRequest = (surpriseId) => {
  return axiosInstance.delete(`surprise/${surpriseId}`);
};

export {
  getSurprisesRequest,
  getSurpriseRequest,
  postSurpriseRequest,
  patchSurpriseRequest,
  deleteSurpriseRequest,
};
