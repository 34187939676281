/* eslint-disable no-use-before-define */
/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import login from './action';

const authenticatedUser = JSON.parse(localStorage.getItem('currentUser'));

export const AuthSlice = createSlice({
  name: 'auth',
  initialState: {
    isAuthenticated: !!authenticatedUser,
    currentUser: authenticatedUser || null,
    isLoading: false,
    error: null,
  },
  reducers: {
    logout(state) {
      localStorage.removeItem('token');
      localStorage.removeItem('currentUser');
      state.isAuthenticated = false;
      state.currentUser = null;
      state.error = null;
    },
  },
  extraReducers: {
    [login.pending]: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    [login.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.error = null;
      state.isAuthenticated = true;
      state.currentUser = payload;
    },
    [login.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
      state.isAuthenticated = false;
      state.currentUser = null;
    },
  },
});

export const selectFullName = ({ auth }) =>
  `${auth.currentUser.first_name} ${auth.currentUser.last_name}`;

export const { logout } = AuthSlice.actions;

export default AuthSlice.reducer;
