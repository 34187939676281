import axiosInstance from '../../../helpers/axios';

export const meRequest = (payload) => {
  return axiosInstance.get(`auth/me?token=${payload}`, {
    headers: {
      Authorization: payload,
      'Content-Type': 'application/json',
      accept: 'application/json',
    },
  });
};

export const loginRequest = (payload) => {
  return axiosInstance.post('auth/login', payload);
};
