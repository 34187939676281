import axiosInstance from '../../../helpers/axios';

const getChaptersRequest = () => {
  return axiosInstance.get('feature');
};

const getChapterRequest = (chapterId) => {
  return axiosInstance.get(`feature/get_by_id/?feature_id=${chapterId}`);
};

const postChapterRequest = (data) => {
  return axiosInstance.post('feature/store', data);
};

const patchChapterRequest = ({ data, chapterId }) => {
  return axiosInstance.patch(`feature/${chapterId}`, data);
};

const deleteChapterRequest = (chapterId) => {
  return axiosInstance.delete(`feature/${chapterId}`);
};

export {
  getChaptersRequest,
  getChapterRequest,
  postChapterRequest,
  patchChapterRequest,
  deleteChapterRequest,
};
