/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { fetchSurprises, fetchSurprise, updateSurprise } from './action';

export const surpriseSlice = createSlice({
  name: 'surprise',
  initialState: {
    surprises: [],
    surprise: null,
    isLoading: false,
    error: null,
  },
  reducers: {
    clearSurprises: (state) => {
      state.surprises = [];
    },
  },
  extraReducers: {
    [fetchSurprises.pending]: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    [fetchSurprises.fulfilled]: (state, action) => {
      state.surprises = action.payload;
      state.isLoading = false;
    },
    [fetchSurprises.rejected]: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    },
    [fetchSurprise.pending]: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    [fetchSurprise.fulfilled]: (state, action) => {
      state.surprise = action.payload;
      state.isLoading = false;
    },
    [fetchSurprise.rejected]: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    },
    [updateSurprise.fulfilled]: (state, action) => {
      state.surprise = action.payload;
    },
  },
});
export const { clearSurprises } = surpriseSlice.actions;

export default surpriseSlice.reducer;
