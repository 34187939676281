import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  deleteDiscount,
  getDiscounts,
  patchDiscount,
  postDiscount,
} from './api';

export const fetchDiscounts = createAsyncThunk(
  'discounts/fetchDiscounts',
  async (_, { rejectWithValue }) => {
    try {
      const res = await getDiscounts();
      return res.data.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const createDiscount = createAsyncThunk(
  'discounts/createDiscount',
  async (data) => {
    const res = await postDiscount(data);
    return res.data.data;
  }
);

export const updateDiscont = createAsyncThunk(
  'discounts/updateDiscount',
  async (data) => {
    const res = await patchDiscount(data);
    return res.data.data;
  }
);

export const removeDiscount = createAsyncThunk(
  'discounts/removeDiscount',
  async (discountId) => {
    const res = await deleteDiscount(discountId);
    return res.data.data;
  }
);
