/* eslint-disable no-underscore-dangle */
/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { fetchChapters, fetchChapter, updateChapter } from './action';

export const chapterSlice = createSlice({
  name: 'chapter',
  initialState: {
    chapters: [],
    chapter: null,
    isLoading: false,
    error: null,
  },
  reducers: {
    setChapter: (state, action) => {
      state.chapter = action.payload;
    },
  },
  extraReducers: {
    [fetchChapters.pending]: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    [fetchChapters.fulfilled]: (state, action) => {
      state.chapters = action.payload;
      state.isLoading = false;
    },
    [fetchChapters.rejected]: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    },
    [fetchChapter.pending]: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    [fetchChapter.fulfilled]: (state, action) => {
      state.chapter = action.payload;
      state.isLoading = false;
    },
    [fetchChapter.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    [updateChapter.fulfilled]: (state, action) => {
      state.chapter = action.payload;
    },
  },
});

export const { setChapter } = chapterSlice.actions;

export default chapterSlice.reducer;
