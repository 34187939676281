import axiosIntance from '../../../helpers/axios';

const getUsers = () => {
  return axiosIntance.get('/users');
};

export const postUser = (payload) => {
  return axiosIntance.post('auth/register', payload);
};

export const patchUser = (data) => {
  return axiosIntance.post('auth/updateProfileAdmin', data);
};

export const deleteUser = (userId) => {
  return axiosIntance.delete(`auth/${userId}`);
};

export const postTeam = (payload) => {
  return axiosIntance.post('team/store', payload);
};

export default getUsers;
